.chatListItem{
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 70px;
  }
  .chatListItem--avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 15px;
  }
  .chatListItem--lines{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #EEE;
    padding-right: 15px;
    margin-left: 15px;
    height: 100%;
    
    flex-wrap: wrap;
    min-width: 0;
  
  }
  .chatListItem--line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .chatListItem--name{  
    font-size: 16px;
    color: #000;
  
  }
  .chatlistItem--date{
    font-size: 12px;
    color: #999;
  }
  .chatListItem--LastMsg{
    font-size: 14px;
    color: #999;
    display: flex;
    width: 100%;
  }
  .chatListItem--LastMsg p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
  .chatListItem--LastMsg p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
  
  .chatListItem:hover {
    background-color: #F5F5F5;
  }